
import { defineComponent, } from 'vue';

import AdminSidebar from '@/components/navbars/AdminSidebar.vue';

export default defineComponent({
  name: 'Sidebars',

  components: { AdminSidebar },
});
