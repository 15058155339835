<template>
  <div class="text-primary text-3xl text-center py-7 border-b border-grey-fp-30">Sidebar</div>
  <div class="flex flex-col h-full">
    <AdminSidebar />
  </div>
</template>

<script lang="ts">
import { defineComponent, } from 'vue';

import AdminSidebar from '@/components/navbars/AdminSidebar.vue';

export default defineComponent({
  name: 'Sidebars',

  components: { AdminSidebar },
});
</script>